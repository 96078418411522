import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from '@angular/common/http';
import { LoaderService } from './loader.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlingService {

  constructor(
    private loaderService: LoaderService,
    private toastr: ToastrService,
    private router: Router,
    private translateService: TranslateService
  ) { }

  formatErrorMessage(errorResponse: HttpErrorResponse): string {
    let errorMessage = '';
    const responseErrorMessages = Object.values(errorResponse.error.errors);
    responseErrorMessages.forEach((message: string) => {
      errorMessage += message + '\n';
    });
    return errorMessage;
  }

  handleBackendError(response: HttpErrorResponse): Observable<never> {
    this.loaderService.hide();
    let errorMessage: string;

    if (response.status === 0 || response.error?.errors === null) {
      errorMessage = this.translateService.instant('SHARED.SOMETHING_WENT_WRONG');
    } else if (response.status === 403) {
      if (response.error.errors === 'Access Denied.') {
        this.toastr.error(response.error.errors);
        this.router.navigate(['/login']);
      } else {
        errorMessage = this.formatErrorMessage(response);
      }
    } else if (response.status === 401) {
      this.toastr.error(response.error.message);
      return throwError(() => response);
    } else if (response.status === 415) {
      errorMessage = this.translateService.instant('GENERIC_ERROR_MESSAGES.UNSUPPORTED_FORMAT');
    } else if (typeof response.error.errors === 'string') {
      errorMessage = response.error.errors;
    } else {
      errorMessage = this.formatErrorMessage(response);
    }

    this.toastr.error(errorMessage);
    return throwError(() => response);
  }

  handleMediaError(response: HttpErrorResponse): Observable<never> {
    this.loaderService.hide();
    this.toastr.error(response.message);
    return throwError(() => response);
  }
}
